import Highcharts from "highcharts";
import HighchartsMore from "highcharts/highcharts-more";
import HighchartsReact from "highcharts-react-official";
import { format } from "date-fns";
HighchartsMore(Highcharts);

const SpiderWebChart = ({ transactionReport, spendingsCategories }) => {
  const date = new Date("2022-04-01");
  const dateCopy = new Date(date.getTime());
  const last3months = new Date(dateCopy.setMonth(date.getMonth() - 3));
  const lastmonth = new Date(dateCopy.setMonth(date.getMonth() - 1));
  const lastHalfYear = new Date(dateCopy.setMonth(date.getMonth() - 6));
  const lastYear = new Date(date.setFullYear(date.getFullYear() - 1));

  const formatedCurrentDate = format(new Date(last3months), "yyyy-MM-dd");
  const formatedLastHalfYearDate = format(new Date(lastHalfYear), "yyyy-MM-dd");
  const formatedLastMonthtDate = format(new Date(lastmonth), "yyyy-MM-dd");
  const formatedLastYearDate = format(new Date(lastYear), "yyyy-MM-dd");

  const lastTreeMonthsData = transactionReport.filter(
    (report) => report.filter((r) => r.month == formatedCurrentDate).length
  );
  const lastThreeMonthTransaction = lastTreeMonthsData.map((reports) => {
    return reports.filter((r) => spendingsCategories.includes(r.category));
  });
  const dataForLastThreeMonth = lastThreeMonthTransaction.map((report) =>
    report.map((r) =>
      Number(Math.abs(r.total.split(" ")[0].split(",").join("")))
    )
  );

  const lastMonthData = transactionReport.filter(
    (report) => report.filter((r) => r.month == formatedLastMonthtDate).length
  );
  const lastMonthTransaction = lastMonthData.map((reports) => {
    return reports.filter((r) => spendingsCategories.includes(r.category));
  });
  const dataForLastMonth = lastMonthTransaction.map((report) =>
    report.map((r) =>
      Number(Math.abs(r.total.split(" ")[0].split(",").join("")))
    )
  );

  const lastHalfYearData = transactionReport.filter(
    (report) => report.filter((r) => r.month == formatedLastHalfYearDate).length
  );
  const lastHalfYearTransaction = lastHalfYearData.map((reports) => {
    return reports.filter((r) => spendingsCategories.includes(r.category));
  });
  const dataForLastHalfYear = lastHalfYearTransaction.map((report) =>
    report.map((r) =>
      Number(Math.abs(r.total.split(" ")[0].split(",").join("")))
    )
  );

  const lastYearData = transactionReport.filter(
    (report) => report.filter((r) => r.month == formatedLastYearDate).length
  );
  const lastYearTransaction = lastYearData.map((reports) => {
    return reports.filter((r) => spendingsCategories.includes(r.category));
  });
  const dataForLastYear = lastYearTransaction.map((report) =>
    report.map((r) =>
      Number(Math.abs(r.total.split(" ")[0].split(",").join("")))
    )
  );

  const categoriesName = lastMonthTransaction.map((report) =>
    report.map((r) => r.category)
  );

  const getImageForCategory = (categoryName) => {
    switch (categoryName) {
      case "home":
        return "https://i.ibb.co/p3R3pxj/household-icon.png";
      case "misc":
        return "https://i.ibb.co/84Lp2DD/other-icon.png";
      case "house":
        return "https://i.ibb.co/VJD3vTZ/home-icon.png";
      case "food":
        return "https://i.ibb.co/G75hp8S/food-icon.png";
      case "entertainment":
        return "https://i.ibb.co/8cn2VhD/leisure-icon.png";
      case "shopping":
        return "https://i.ibb.co/Db4yNNx/shopping-cart-icon.png";
      case "wellness":
        return "https://i.ibb.co/vY0YfZZ/health-icon.png";
      case "transport":
        return "https://i.ibb.co/SQn1rRC/transport-icon.png";
      default:
        return "https://i.ibb.co/Db4yNNx/shopping-cart-icon.png";
    }
  };
  const getCategoryFullName = (categoryName) => {
    switch (categoryName) {
      case "home":
        return "Household & Services";
      case "misc":
        return "Other";
      case "house":
        return "Home Improvements";
      case "food":
        return "Food & Drinks";
      case "entertainment":
        return "Leisure";
      case "shopping":
        return "Shopping";
      case "wellness":
        return "Health & Beauty";
      case "transport":
        return "Transport";
      default:
        return "Shopping";
    }
  };

  const options = {
    chart: {
      polar: true,
      height: "580px",
      type: "area",
    },

    title: null,

    pane: {
      size: "80%",
      center: ["50%", "50%"],
    },

    xAxis: {
      categories: [...categoriesName[0]],
      gridLineColor: "#8096DE",
      labels: {
        formatter: function () {
          return (
            getCategoryFullName(this.value) +
            "<br>" +
            '<img src="' +
            getImageForCategory(this.value) +
            '" style="width:40px;height:40px; padding: 10px; background: rgba(247, 251, 255, 0.5); border-radius:50%" />'
          );
        },
        type: "category",
        useHTML: true,
        style: {
          fontFamily: "Avenir Next LT Pro",
          fontStyle: "normal",
          fontWeight: 500,
          fontSize: "18px",
          color: "#2E6CC6",
          textAlign: "center",
          textTransform: "capitalize",
        },
      },
      tickmarkPlacement: "on",
      lineWidth: 0,
    },

    yAxis: {
      gridLineInterpolation: "circle",
      lineWidth: 0,
      gridLineDashStyle: "longdash",
      gridLineColor: "#8096DE",
      min: 0,
      max: 15000,
      labels: {
        style: {
          fontSize: "12px",
          fontWeight: 500,
          lineHeight: "16px",
          color: "#B7BDD0",
        },
      },
    },

    tooltip: {
      pointFormat: "<b>{point.y:.1f}Kr</b>/{series.name}",
      style: {
        color: "#2E6CC6",
      },
    },

    legend: {
      enabled: false,
      align: "left",
      verticalAlign: "bottom",
      x: 0,
      y: 0,
      layout: "vertical",
      itemMarginTop: 5,
      itemMarginBottom: 5,
      symbolPadding: 10,
      symbolWidth: 10,
      style: {
        fontWeight: 500,
        fontSize: "16px",
        lineHeight: "22px",
        color: "#C4C4C4",
      },
    },
    plotOptions: {
      series: {
        threshold: -Infinity,
      },
    },
    series: [
      {
        name: "Last month",
        color: "#102463",
        fillColor: "rgba(16,36,99,0.1)",
        data: dataForLastMonth[0],
        marker: {
          states: {
            hover: {
              fillColor: "#102463",
            },
          },
          fillColor: "#FFFFFF",
          lineWidth: 2,
          lineColor: null,
          radius: 4,
          symbol: "circle",
        },
        pointPlacement: "on",
      },
      {
        name: "Last 3 month",
        color: "#20DA88",
        fillColor: "rgba(32,218,136,0.1)",
        data: dataForLastThreeMonth[0],
        marker: {
          states: {
            hover: {
              fillColor: "#20DA88",
            },
          },
          fillColor: "#FFFFFF",
          lineWidth: 2,
          lineColor: null,
          radius: 4,
          symbol: "circle",
        },
        pointPlacement: "on",
      },
      {
        name: "Last half year",
        color: "#F8D150",
        fillColor: " rgba(248,209,80,0.1)",
        data: dataForLastHalfYear[0],
        marker: {
          states: {
            hover: {
              fillColor: "#F8D150",
            },
          },
          fillColor: "#FFFFFF",
          lineWidth: 2,
          lineColor: null,
          radius: 4,
          symbol: "circle",
        },
        pointPlacement: "on",
      },
      {
        name: "Last year",
        color: "#DB3D44",
        fillColor: "rgba(219,61,68,0.1)",
        data: dataForLastYear[0],
        marker: {
          states: {
            hover: {
              fillColor: "rgba(219,61,68,1)",
            },
          },
          fillColor: "#FFFFFF",
          lineWidth: 2,
          lineColor: null,
          radius: 4,
          symbol: "circle",
        },
        pointPlacement: "on",
      },
    ],

    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 600,
          },

          chartOptions: {
            legend: {
              align: "center",
              verticalAlign: "bottom",
            },

            pane: {
              size: "70%",
            },
          },
        },
      ],
    },
  };
  return (
    <div>
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};
export default SpiderWebChart;
