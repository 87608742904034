import React from "react"
import PropTypes from "prop-types"
import { WaveTopBottomLoading } from 'react-loadingg';

import TransactionAggregator from './TransactionAggregator'
var parse = require('html-react-parser');

import I18n from "i18n/translations";

const errorMessages = {
  BAD_REQUEST: 'Bad request or wrong request parameters. Please, check the link',
  USER_CANCELLED: 'User canselled interactions',
  INTERNAL_ERROR: 'Something went wrong. Service is currently unavailable. Please, try again later.',
  TEMPORARY_ERROR: 'Service is currently partially unavailable due to a provider issue. Please, try again in a minute.',
}

const _ = require("lodash");

class TheiaCollector extends React.Component {
  constructor(props) {
    super(props);
    // Set locale
    I18n.locale = props.locale;
    this.customerTranslations = (props.customerConfig?.translations || {})[I18n.locale];

    this.navigationStack = [];


    // this.config = {
    //   ...{
    //       introDescription: I18n.t('link.intro.description'),
    //       showPolicyPrompt: true,
    //     },
    //   ...(props.customerConfig || {})[I18n.locale]
    // };

    let status = 'intro';
    if (props.consentGiven) { status = 'ssn' }

    this.state = {
      status: status,
      consentGiven: props.consentGiven || false,
      dataId: props.dataId,
      ssn: '',
    };
  }

  t = (key) => {
    if (this.customerTranslations) {
      return _.get(this.customerTranslations, key, I18n.t(key))
    } else {
      return I18n.t(key)
    }
  }

  pushCurrentStatus = () => {
    this.navigationStack.push(this.state.status);
  }

  start = (event) => {
    this.pushCurrentStatus();
    this.setState({ status: 'ssn' });
  }

  changeSSN = (event) => {
    this.setState({ ssn: event.target.value });
  }

  save_ssn = (event) => {
    this.pushCurrentStatus();

    fetch(this.props.dataSubmitUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
              authenticity_token: this.props.authenticityToken,
              client_id: this.props.clientId,
              data_id: this.state.dataId,
              report: {
                customer_ssn: this.state.ssn,
              },
            }),
    })
    .then(respJSON => {
      if (!respJSON.ok) {
        throw Error(respJSON.statusText);
      }

      return respJSON.json();
    })
    .then(resp => {
      if (resp.status == 'error') {
        throw Error(resp.message);
      }
    })
    .catch(error => console.log(error));

    this.setState({ status: 'aispAuth' });
  }

  cancel = () => {
    this.callback(null, 'error', 'USER_CANCELLED');
  }

  policies = () => {
    this.pushCurrentStatus()
    this.setState({ status: 'policies' });
  }

  termsOfUse = () => {
    this.pushCurrentStatus();
    this.setState({ status: 'termsOfUse' });
  }

  privacyPolicy = () => {
    this.pushCurrentStatus();
    this.setState({ status: 'privacyPolicy' });
  }

  goBack = () => {
    this.setState({ status: this.navigationStack.pop() });
  }

  handleInputChange = (event) => {
    const target = event.target;
    const value = target.value;
    let name = target.name;

    if (target.name.match(/phone/)) {
      name = 'phoneNumber';
    } else if (target.name.match(/birth/)) {
      name = 'birthDate';
    }

    this.setState({
      [name]: value
    });
  }

  changeConsent = (event) => {
    this.setState({ consentGiven: event.target.checked });
  }

  redirectBack = (code = null, errorType = null) => {
    let params = {data_id: this.state.dataId, client_id: this.props.clientId};
    const errorMessage = errorMessages[errorType],
          url = new URL(this.props.callbackUrl, document.baseURI);

    if (errorType) {
      params.status = 'error';

      if (this.props.iframe) {
        params.error = {
          status: errorType,
          message: errorMessage,
        };
      } else {
        params.error = errorType;
        params.message = errorMessage;
      }
    } else {
      params.code = code;
    }

    if (this.props.iframe) {
      // window.parent.postMessage(JSON.stringify({ ...params, url: url}), window.parent.location.origin);
      window.parent.postMessage(JSON.stringify({ ...params, url: url}), '*');
    } else {
      Object.entries(params).forEach(([key, val]) => url.searchParams.append(key, val));

      window.location = `${url}`;
    }
  }

  callback = (code, status, errorType = null, test_status) => {
    let errorMessage = errorMessages[errorType];
    const data = { code: code, status: status, error: errorType, authenticity_token: this.props.authenticityToken,
      client_id: this.props.clientId, scope: this.props.scope, test: this.props.test, test_status: test_status };
    const url = new URL(this.props.callbackUrl, document.baseURI);
    // console.log(this.state, this);

    if (errorType) {
      this.setState({ status: 'redirect' });

      this.redirectBack(null, errorType);
    } else {
      this.setState({ status: 'collect_data' });

      fetch(this.props.dataCollectionURL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
      .then(resp => resp.json())
      .then(jsonResp => {
        const dataId = jsonResp.data_id;

        this.setState({ status: 'aispAuth', dataId: dataId });
      })
      .catch(error => alert('Smth went wrong'));
    }
  }

  // Error format: { status: 'USER_CANCELLED', message: '' }
  aispCallback = (dataId, error = undefined) => {
    this.setState({ status: 'redirect' });
    const url = this.props.callbackUrl,
          message = { code: dataId, error: error?.message, client_id: this.props.clientId };

    if (this.props.iframe) {
      // window.parent.postMessage(JSON.stringify({ ...message, url: url}), window.parent.location.origin);
      window.parent.postMessage(JSON.stringify({ ...message, url: url}), '*');
    } else {
      const params = new URLSearchParams(Object.fromEntries(Object.entries(message).filter(([key, val]) => val))).toString();
      window.location = `${url}?${params}`;
    }
  }

  renderBackButton() {
    if (this.navigationStack.length) {
      return (
        <button className="btn btn-link float-left" onClick={ this.goBack }>
          <i className="fas fa-angle-left" />
        </button>
      )
    }
  }

  renderHeader(content) {
    return (
      <h3 className="text-center">
        { this.renderBackButton() }
        { content }
      </h3>
    )
  }

  renderCancelButton() {
    return <button className="btn btn-link btn-block text-muted font-size-sm" onClick={ this.cancel }>{ I18n.t('link.intro.cancel') }</button>;
  }

  renderForm() {
    switch(this.state.status) {
      case 'intro': {
        return (
          <div>
            <div className="row justify-content-center">
              <div className="col-md-12">
                { this.renderHeader(this.t('link.aisp.title')) }
                <div className="mb-4">
                  { parse(this.t('link.aisp.title_description')) }
                </div>

                <button className="btn btn-block btn-primary font-weight-bold" onClick={ this.start } >{ this.t('link.aisp.intro.next') }</button>
                <p className="small mt-1">
                  { parse(I18n.t('link.aisp.intro.consent_text', { name: this.props.customerName })) } <a className="btn btn-link text-left p-0" onClick={ this.policies }>{I18n.t('link.intro.terms_of_use_title')}</a>. { parse(I18n.t('link.aisp.intro.customer_disclosure_text', { name: this.props.customerName })) }
                </p>
                { this.renderCancelButton() }
              </div>
            </div>

          </div>
        )
        break;
      }
      case 'ssn': {
        return (
          <div>
            <div className="row justify-content-center">
              <div className="col-md-12">
                { this.renderHeader(I18n.t('link.ssn.title')) }
                <div className="mb-4">
                  { parse(I18n.t('link.ssn.title_description')) }
                </div>

                <div className="form-group tel required report_ssn">
                  <label className="form-control-label tel required" htmlFor="report_ssn">{I18n.t('link.ssn.label')} <abbr title="required">*</abbr></label>
                  <input className="form-control string tel required" type="tel" name="ssn" id="report_ssn" autocomplete="off" onChange={ this.changeSSN }/>
                </div>

                <button className="btn btn-block btn-primary font-weight-bold" onClick={ this.save_ssn } disabled={ this.state.ssn.length != 11 }>{ I18n.t('link.ssn.next') }</button>
                { this.renderCancelButton() }
              </div>
            </div>

          </div>
        )
        break;
      }
      case 'aispAuth': {
        return (
          <React.Fragment>
            <TransactionAggregator { ...this.props} dataId={ this.state.dataId } callback={ this.aispCallback } />
            {/*<TransactionAggregator { ...this.props} dataId={ this.state.dataId } callback={ this.aispCallback } financialInstitutions={ [{id: "f58e31ebaf625c15a9601aa4deac83d0", name: "Demo Open Banking Redirect", logo_url: "https://cdn.tink.se/provider-images/placeholder.png"}, {id: "f58e31ebaf625c15a9601aa4deac83d0", name: "Demo Open Banking Redirect", logo_url: "https://cdn.tink.se/provider-images/placeholder.png"}] } />*/}
            { this.renderCancelButton() }
          </React.Fragment>
        )
        break;
      }
      case 'policies': {
        return (
          <React.Fragment>
            { this.renderHeader(I18n.t('link.policies.title')) }
            <div className="row justify-content-center overflow-auto">
              <div className="col-md-12">
                <ul className="list-unstyled">
                  {
                    I18n.t('link.aisp.policies.points').map((pointText, idx) => {
                      return (
                        <li className="mb-4" key={ idx }>
                          <i className="fas fa-check-circle text-primary" /> <span className="lead">{ pointText.title }</span>
                          <div>{ pointText.description }</div>
                        </li>
                      )
                    })
                  }
                </ul>
                <div className="mb-4">
                  <button className="btn btn-primary text-left mr-4" onClick={ this.privacyPolicy }>{ I18n.t('link.policies.privacy_policy') }</button>
                  <button className="btn btn-primary text-left" onClick={ this.termsOfUse }>{ I18n.t('link.policies.terms_of_use') }</button>
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-md-12">
                <button className="btn btn-block btn-primary" onClick={ this.goBack }>{ I18n.t('common.back') }</button>
              </div>
            </div>
          </React.Fragment>
        )
        break;
      }
      case 'termsOfUse': {
        return (
          <React.Fragment>
            <div className="row justify-content-center overflow-auto h-100">
              <div className="col-md-12" style={{ height: '75vh' }}>
                <iframe width="100%" height="100%" frameBorder="0" src="/terms"></iframe>
              </div>
            </div>
            <div className="row justify-content-center mt-2">
              <div className="col-md-12">
                <button className="btn btn-block btn-primary" onClick={ this.goBack }>{ I18n.t('common.back') }</button>
              </div>
            </div>
          </React.Fragment>
        )
        break;
      }
      case 'privacyPolicy': {
        return (
          <React.Fragment>
            <div className="row justify-content-center overflow-auto h-100">
              <div className="col-md-12" style={{ height: '75vh' }}>
                <iframe width="100%" height="100%" frameBorder="0" src="/theia_privacy"></iframe>
              </div>
            </div>
            <div className="row justify-content-center mt-2">
              <div className="col-md-12">
                <button className="btn btn-block btn-primary" onClick={ this.goBack }>{ I18n.t('common.back') }</button>
              </div>
            </div>
          </React.Fragment>
        )
        break;
      }
      case 'collect_data': {
        return (
          <div className="row align-items-center d-flex" style={{ minHeight: 350 }}>
            <div className="col-2">
              <div className="loading-squared"><WaveTopBottomLoading color="#4579aa" /></div>
            </div>
            <div className="col align-items-center d-flex">
              <div>{ I18n.t('link.collect_data.message') }</div>
            </div>
          </div>
        )
      }
      case 'redirect': {
        return (
          <div className="row align-items-center d-flex" style={{ minHeight: 350 }}>
            <div className="col-2">
              <div className="loading-squared"><WaveTopBottomLoading color="#4579aa" /></div>
            </div>
            <div className="col align-items-center d-flex">
              <div>{ I18n.t('link.redirect.message') }</div>
            </div>
          </div>
        )
      }
    }
  }

  render () {
    return (
      <React.Fragment>
        { this.renderForm() }
      </React.Fragment>
    );
  }
}

TheiaCollector.propTypes = {
  dataSubmitUrl: PropTypes.string,
  callbackUrl: PropTypes.string,
  dataCollectionURL: PropTypes.string,
  authenticityToken: PropTypes.string,
  agreementsUrl: PropTypes.string,
  agreementBankData: PropTypes.array,
  clientId: PropTypes.string,
  scope: PropTypes.string,
  test: PropTypes.bool,
  customerName: PropTypes.string,
  customerConfig: PropTypes.object,
  customTranslations: PropTypes.object,
};
export default TheiaCollector
