import React from "react"
import PropTypes from "prop-types"
import { WaveTopBottomLoading } from 'react-loadingg';
var parse = require('html-react-parser');

import I18n from "i18n/translations";

class TinkLink extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errorMessage: null,
      loading: true,
      // loadingState: 'start',
      // dataId: props.dataId,
    };
  }

  componentDidMount() {
    this.getLink();
    window.addEventListener("message", this.messageCallback, false);
    // this.initTinkCallback();
  }

  componentWillUnmount() {
    window.removeEventListener("message", this.messageCallback);
  }

  messageCallback = (event) => {
    if(event.origin == 'https://link.tink.com') {
      const data = JSON.parse(event.data);
      console.log(data);

      // {"type":"status","data":{"loading":true}}
      if (data.data?.loading !== undefined) { this.setState({ loading: data.data.loading }) };

      // {"type":"none","additionalInformation":{"credentialsId":"342bf51bf10e4498a4b3458407b3f4bc"}}
      if (data.additionalInformation && data.type === 'none') { this.props.callback(data.additionalInformation) };

      if (data.type == 'error') {
        switch (data.error.status) {
          case 'USER_CANCELLED':
            // { "type": "error", "error": { "status": "USER_CANCELLED", "message": "Vi beklager, det oppstod en feil", "reason": "USER_CANCELLED", "trackingId": "07575214-ddb7-4a75-a5db-c4bef8b8d657"}}
            this.props.callback({ cancelled: true });

            break;
          default:
            this.props.callback({ error: true, status: data.error.status, message: data.error.message });
        }
      }
    }
  }

  // initTinkCallback = () => {
  //   window.addEventListener("message", (event) => {
  //     if(event.origin == 'https://link.tink.com') {
  //       // console.log(event.data);
  //       const data = JSON.parse(event.data);

  //       // {"type":"status","data":{"loading":true}}
  //       if (data.data?.loading !== undefined) { this.setState({ loading: data.data.loading }) };

  //       // {"type":"none","additionalInformation":{"credentialsId":"342bf51bf10e4498a4b3458407b3f4bc"}}
  //       if (data.additionalInformation && data.type === 'none') { this.callback(data.additionalInformation) };

  //       if (data.type == 'error') { this.props.callback(this.props.dataId, data.error); }
  //     }
  //   }, false);
  // }

  setErrorMessage = (error) => {
    this.setState({ errorMessage: error.toString(), loading: false });
  }

  getLink = () => {
    this.setState({ loading: true });

    let url = new URL(this.props.getAispUrl, window.location.origin);
    // Object.entries({ client_id: this.props.clientId }).forEach(([key, value]) => url.searchParams.append(key, value));

    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
              authenticity_token: this.props.authenticityToken,
              client_id: this.props.clientId,
              data_id: this.props.dataId,
              test: this.props.test,
            }),
    })
    .then(respJSON => {
      if (!respJSON.ok) {
        throw Error(respJSON.statusText);
      }
      // console.log(respJSON);

      return respJSON.json();
    })
    .then(resp => {
      if (resp.error_message) {
        throw Error(resp.message);
      }

      this.setState({ aispUrl: resp.auth_link });
    })
    .catch(error => this.setErrorMessage(error));
  }

  // callback = (additionalData) => {
  //   this.setState({ loading: true });

  //   fetch(this.props.aispCallbackUrl, {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //     body: JSON.stringify({
  //             authenticity_token: this.props.authenticityToken,
  //             client_id: this.props.clientId,
  //             data_id: this.state.dataId,
  //             additional_data: additionalData,
  //           }),
  //   })
  //   .then(respJSON => {
  //     if (!respJSON.ok) {
  //       throw Error(respJSON.statusText);
  //     }

  //     return respJSON.json();
  //   })
  //   .then(resp => {
  //     if (resp.error_message) {
  //       throw Error(resp.message);
  //     }

  //     if (resp.status === 'ok') {
  //       this.props.callback(this.state.dataId);
  //     } else {
  //       setTimeout(() => {
  //         this.callback(additionalData);
  //       }, 3000)
  //     }

  //   })
  //   .catch(error => this.setErrorMessage(error));
  // }

  renderLoading = () => {
    return (
      <div className="d-flex justify-content-center">
        <div style={{ width: 420 }}>
          <div className="row align-items-center d-flex" style={{ minHeight: 450 }}>
            <div className="col-2">
              <div className="loading-squared"><WaveTopBottomLoading color="#4579aa" /></div>
            </div>
            <div className="col align-items-center d-flex">
              <div>{ I18n.t('bankid.loading') }</div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderFrame = () => {
    if (this.state.aispUrl) {
      return (
        <div className={ `justify-content-center overflow-hidden h-100 ${ this.state.loading && 'd-none' || 'd-flex' }` }>
          <iframe width="420" height="540" frameBorder="0" src={ this.state.aispUrl } />
        </div>
      )
    }
  }

  render () {
    return (
      <React.Fragment>
        {
          this.state.errorMessage &&
            <div className="text-center">
              <p>{ I18n.t('bankid.error.title') }</p>
              <p>{ parse(this.state.errorMessage) }</p>
              <button className="btn btn-block btn-primary" onClick={ this.restartSession }>{ I18n.t('bankid.error.retry') }</button>
            </div>
        }

        { this.state.loading && this.renderLoading() }
        { this.renderFrame() }
      </React.Fragment>
    );
  }
}

TinkLink.propTypes = {
  authenticityToken: PropTypes.string,
  getAispUrl: PropTypes.string,
  aispCallbackUrl: PropTypes.string,
  clientId: PropTypes.string,
  dataId: PropTypes.string,
  scope: PropTypes.string,
  test: PropTypes.bool,
  callback: PropTypes.func,
};
export default TinkLink
